<template>
  <f7-popup
    class="global-admin-message-popup"
    tablet-fullscreen
    :opened="opened"
    @popup:closed="fixPopupCloseOnSafari"
    @popup:opened="adjustLayout"
  >
    <f7-page ref="popup">
      <f7-navbar
        ref="navbar"
        title="Message ou annonce global (sur la page EDL en cours)"
      >
        <f7-nav-right>
          <f7-link
            @click="close"
            icon-ios="f7:multiply_circle"
          />
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <f7-block-title>Message / Alerte globale sur la page EDL en cours</f7-block-title>
        <p><em>Laisser vide pour ne pas afficher de message</em></p>
        <f7-text-editor
          :value="localValueMessage"
          :buttons="buttons"
          placeholder="Rédiger ici un message qui apparaîtra en haut de la page EDL en cours..."
          style="height: 40vh;"
          @texteditor-change="updateMessage"
        />
      </f7-block>
      <f7-block>
        <edl-submit-row
          name="Enregistrer"
          @submit="save"
        />
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import EdlSubmitRow from "@/components/pages/Edl/EdlSubmitRow.vue";

export default {
  name: "GlobalAdminMessagePopup",
  components: {EdlSubmitRow},
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
  },
  emits: ['update', 'close'],
  data: function () {
    return {
      localValueMessage: this.message,
      buttons: [
        ['bold', 'italic', 'underline'],
        ['orderedList', 'unorderedList'],
        ['link'],
        ['subscript', 'superscript'],
        ['undo', 'redo']
      ]
    };
  },
  watch: {
    message: {
      handler: function (newValue) {
        this.localValueMessage = newValue;
      },
      immediate: true
    },
  },
  created() {
    window.addEventListener("resize", this.adjustLayout);
  },
  unmounted() {
    window.removeEventListener("resize", this.adjustLayout);
  },
  methods: {
    updateMessage: function (newValue) {
      this.localValueMessage = newValue;
    },
    save: function () {
      let message = this.localValueMessage;

      //Case an existing text is now empty, there remains a <br> that we remove here
      if(message === '<br>') {
        message = '';
      }

      this.$emit('update', {
        message
      });
      this.$emit('close');
    },
    adjustLayout: function () {
      let alwaysShow = this.$refs.alwaysShow;
      let popup = this.$refs.popup;
      let navbar = this.$refs.navbar;

      this.scrollUnderStyle =
        (alwaysShow !== undefined && popup !== undefined && navbar !== undefined) ? {
          height: (popup.$el.clientHeight - navbar.$el.clientHeight - alwaysShow.clientHeight - 10) + 'px'
        } : null;
    },
    close: function () {
      this.$emit('close');
    },
    fixPopupCloseOnSafari: function () {
      //Patch to fix Safari issue, popup still rendered on screen after close until resize
      window.dispatchEvent(new Event('resize'));
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-under {
  border-top: 1px solid var(--f7-block-strong-border-color);
  overflow-y: scroll;
}
.center {
  text-align: center;
}
.arrow-icon i {
  font-size: 16px;
}
.section-icon {
  position: relative;
  top: 20px;
  left: 2px;
  font-size: 14px;
  z-index: 2;
}
</style>
